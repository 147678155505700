import * as React from "react"
import { _h2, _h3 } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _customMarginRow } from "../../shared/row.styles"
import { Row, Col } from "react-flexbox-grid"
import { GenericItem } from "../../shared"

interface InsightsProps {
    title: string | JSX.Element
    lead: string
    insights: InsightItem[]
}

interface InsightItem {
    title: string
    description: string
    image: string
}

export const Insight: React.FC<InsightsProps> = ({ title, lead, insights }) => {
    return (
        <>
            <Row center="xs">
                <Col lg={6} md={10} sm={12}>
                    <_h2 fontSizeSm={36}>{title}</_h2>
                    <_Blockquote align={"center"}>{lead}</_Blockquote>
                </Col>
            </Row>

            <_customMarginRow center="xs">
                {insights.map((insight, index: number) => {
                    return (
                        <Col lg={4} md={6} sm={12} key={index}>
                            <GenericItem
                                title={insight.title}
                                lead={insight.description}
                                image={insight.image}
                                cardImageClassName="smallImage"
                                itemMaxWidth="255px"
                            />
                        </Col>
                    )
                })}
            </_customMarginRow>
        </>
    )
}
