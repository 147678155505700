import * as React from "react"
import { Layout, _Section, HeadComponent } from "../../components/shared"
import { graphql } from "gatsby"
import { Hero, ChecklistSection } from "../../components/productsPage/shared"
import DebriefImage from "../../images/debrief/debrief.svg"
import debriefListImage from "../../images/debrief/4-questions.svg"
import debriefList2Image from "../../images/debrief/pilots.svg"
import { Insight, DebriefHelpSection } from "../../components/productsPage/Debrief"
import { _h2 } from "../../components/shared/headline.styles"
import { _Blockquote } from "../../components/shared/blockquote.styles"
import { _linkButton, _linkBlockButton } from "../../components/shared/buttons"
import { prismicGetText, extractPrismicNode, prismicGetRichHeader, prismicGetRichText } from "../../utils/prismicHelper"
import { getWindowDimensions, checkIfMobile } from "../../utils"
import { PageWrapper } from "../../utils/PageWrapper"

export const prismicQuery = graphql`
    {
        prismic {
            ...debriefFragment
        }
    }
`

interface DebriefPageProps {
    data: {
        prismic: {
            allProduct_debriefs: {
                edges: any
            }
        }
    }
}

const DebriefPage: React.FC<DebriefPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allProduct_debriefs")

    if (!doc) return null

    const dueDiligenceList = doc.due_diligence.map((element: { due_diligence_element: Array<{ text: any }> }) => {
        return prismicGetText(element.due_diligence_element)
    })

    const questionList = doc.questions.map((question: { question: Array<{ text: any}> }) => {
        return prismicGetRichText(question.question)
    })

    const [, setIsMobile] = React.useState<boolean>(checkIfMobile(getWindowDimensions()))
    React.useEffect(() => {
        function handleResize() {
            setIsMobile(checkIfMobile(getWindowDimensions()))
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    return (
        <>
            <HeadComponent
                title="Debrief | Radicle"
                keywords={prismicGetText(doc.seo_keywords_debrief)}
                description={prismicGetText(doc.seo_description_debrief)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Debrief"
                            title={prismicGetRichHeader(doc.debrief_title_v2)}
                            lead={prismicGetText(doc.debrief_lead)}
                            buttonLabel={prismicGetText(doc.debrief_cta_label)}
                            anchorButtonTo="#howDebriefCanHelp"
                            image={DebriefImage}
                            headerFontSize={52}
                            leftSideLgWidth={6}
                            heroImage={{
                                height: "auto",
                                isBlured: false
                            }}
                            showImageOnMobile={false}
                        />
                    </_Section>

                    <_Section>
                        <Insight
                            title={prismicGetRichHeader(doc.insight_title_v2)}
                            lead={prismicGetText(doc.insight_lead)}
                            insights={[
                                {
                                    title: prismicGetText(doc.insight_title_1),
                                    description: prismicGetText(doc.insight_description_1),
                                    image: doc.insight_image_1.url
                                },

                                {
                                    title: prismicGetText(doc.insight_title_2),
                                    description: prismicGetText(doc.insight_description_2),
                                    image: doc.insight_image_2.url
                                },

                                {
                                    title: prismicGetText(doc.insight_title_3),
                                    description: prismicGetText(doc.insight_description_3),
                                    image: doc.insight_image_3.url
                                }
                            ]}
                        />
                    </_Section>

                    <_Section>
                        <ChecklistSection
                            title={prismicGetRichHeader(doc.framework_title_v2)}
                            lead={prismicGetText(doc.framework_lead)}
                            list={questionList}
                            image={debriefListImage}
                            imagePosition="right"
                            maxTitleWidth="485px"
                        />
                    </_Section>

                    <_Section>
                        <div id="howDebriefCanHelp" style={{ position: "absolute", top: "-100px" }} />
                        <DebriefHelpSection
                            exploreButtonLabel={prismicGetText(doc.cta_label_explore_debrief)}
                            items={doc.body[0].fields}
                        />
                    </_Section>

                    <_Section>
                        <ChecklistSection
                            title={prismicGetRichHeader(doc.duediligence_title_v2)}
                            lead={prismicGetText(doc.duediligence_lead)}
                            list={dueDiligenceList}
                            image={debriefList2Image}
                            imagePosition="right"
                            twoColumns={true}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default DebriefPage
