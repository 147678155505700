import * as React from "react"

import { _h2, _h3, _description } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _customMarginRow } from "../../shared/row.styles"
import { _button } from "../../shared/buttons"
import { Row, Col } from "react-flexbox-grid"
import { DebriefNewsletterModal } from "./DebriefNewsletterModal"
import Slider from "react-slick"
import { ImageCard, _customAlignCol } from "../../shared/"
import { prismicGetText } from "../../../utils/prismicHelper"
import { AppStateContext } from "../../../utils/PageWrapper"
import { SliderSettings } from "../../../components/shared/SliderSettings"

interface DebriefHelpProps {
    items: HelpItem[]
    exploreButtonLabel: string
}

interface HelpItem {
    type_demo_debrief: any
    title_demo_debrief: any
    video_demo_debrief: {
        url: string
    }
    pdf_demo_debrief: {
        url: string
    }
    cover_image_report: {
        url: string
    }
}

export const DebriefHelpSection: React.FC<DebriefHelpProps> = ({ items, exploreButtonLabel }) => {
    const [modalOpen, setModalOpen] = React.useState(false)
    const appState = React.useContext(AppStateContext)

    const closeModal = () => {
        setModalOpen(false)
    }

    const openModal = () => {
        setModalOpen(true)
    }

    if (!appState.isReady) return null

    const desktopContent = items.map(item => {
        return (
            <>
                <Row middle="lg">
                    <Col lg={6}>
                        <ImageCard onClick={openModal} imageUrl={item.cover_image_report.url} />
                    </Col>

                    <_customAlignCol align="left" lg={5} lgOffset={1}>
                        <_description align="left">{prismicGetText(item.type_demo_debrief)}</_description>
                        <_h3 align="left">{prismicGetText(item.title_demo_debrief)}</_h3>

                        <_button marginTop={2} onClick={openModal}>
                            {exploreButtonLabel}
                        </_button>
                    </_customAlignCol>
                </Row>
            </>
        )
    })

    const mobileContent = items.map(item => {
        return (
            <>
                <_description uppercase={true} align="left">
                    {prismicGetText(item.type_demo_debrief)}
                </_description>

                <_h3 marginTop={"0"} fontSizeSm={36} align="left">
                    {prismicGetText(item.title_demo_debrief)}
                </_h3>

                <ImageCard onClick={openModal} imageUrl={item.cover_image_report.url} />
            </>
        )
    })

    return (
        <>
            <DebriefNewsletterModal isOpen={modalOpen} closeModal={closeModal} />
            <_customMarginRow center="md">
                <Col sm={12}>
                    <Slider {...SliderSettings({ slidesToShow: 1 })}>
                        {appState.isMobile ? mobileContent : desktopContent}
                    </Slider>

                    {appState.isMobile ? (
                        <_button marginTop={2} onClick={openModal}>
                            {exploreButtonLabel}
                        </_button>
                    ) : null}
                </Col>
            </_customMarginRow>
        </>
    )
}
